<section class="section" id="contact" style="background-image: url(assets/images/bg/bg-about.png)">
  <div class="container mt-5">
    <div class="row justify-content-center">

      <div class=" col-lg-6 text-center mb-3">
        <h1 class="mb-3"> <span class="text-primary"> {{'contact.header-1' | translate}} </span> {{'contact.header-2' | translate}} </h1> 
        <p> {{'contact.p1' | translate}}</p>
        <hr class="mt-4" style="width: 35%; border: 3px solid #CF0E0E;">
      </div>
    </div>

    <div style="width: 100%;">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15956.250868436284!2d104.0652137!3d1.1151026!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d0025037edd0b60!2sInforsys%20Indonesia!5e0!3m2!1sid!2sid!4v1655455525549!5m2!1sid!2sid"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <br><br>
    <div class="row align-items-center justify-content-center mb-3">
      <div class="col-lg-7">
        <div class="custom-form">
          <div id="message"></div>
          <form [formGroup]="contactForm">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <i-feather class="icon-xs icon mr-2 text-primary" name="user"></i-feather>
                  <label for="name" class="font-size-15"> {{'contact.name' | translate}} </label>
                  <input id="name" type="text" class="form-control" autocomplete="off" placeholder="{{'contact.yourName' | translate}}"
                    formControlName="name" required
                    [ngClass]="{ 'is-invalid': f.name.touched && f.name.errors, 'is-valid': f.name.valid }">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <i-feather class="icon-xs icon mr-2 text-primary" name="mail"></i-feather>
                  <label for="name" class="font-size-15">{{'contact.email' | translate}}</label>
                  <input id="name" type="text" class="form-control" autocomplete="off" placeholder="{{'contact.yourEmail' | translate}}"
                    formControlName="email" required
                    [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors, 'is-valid': f.email.valid }">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <i-feather class="icon-xs icon mr-2 text-primary" name="phone-call"></i-feather>
                  <label for="email" class="font-size-15"> {{'contact.phone' | translate}} </label>
                  <input id="email" type="email" class="form-control" autocomplete="off" placeholder="{{'contact.yourPhone' | translate}}"
                    formControlName="phone" required
                    [ngClass]="{ 'is-invalid': f.phone.touched && f.phone.errors, 'is-valid': f.phone.valid }">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <i class="mdi mdi-office-building h4 text-primary mr-2"></i>
                  <label for="email" class="font-size-15"> {{'contact.company' | translate}} </label>
                  <input id="email" type="email" class="form-control" style="margin-top: -5px;" autocomplete="off"
                    placeholder="{{'contact.yourCompany' | translate}}" formControlName="compName" required
                    [ngClass]="{ 'is-invalid': f.compName.touched && f.compName.errors, 'is-valid': f.compName.valid }">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <i class="mdi mdi-factory text-primary h4 mr-2"></i>
                  <label for="email" class="font-size-15"> {{'contact.industry' | translate}} </label>
                  <select id="email" type="email" class="form-control" style="margin-top: -5px;"
                    formControlName="compType" required 
                    [ngClass]="{ 'is-invalid': f.compType.touched && f.compType.errors, 'is-valid': f.compType.valid }">
                    <option value="" disabled selected hidden>{{'contact.yourIndustry' | translate}}</option>
                    <option value="Manufaktur">{{'contact.optionManufacture' | translate}}</option>
                    <option value="Trading & Distribution">{{'contact.optionTrading' | translate}}</option>
                    <option value="Konstruksi">{{'contact.optionConstruction' | translate}}</option>
                    <option value="Jasa">{{'contact.optionService' | translate}}</option>
                    <option value="Lainnya"> {{'contact.optionOther' | translate}} </option>
                  </select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <i class="mdi mdi-account-tie h4 text-primary mr-2"></i>
                  <label for="email" class="font-size-15"> {{'contact.position' | translate}} </label>
                  <input id="email" type="email" class="form-control" style="margin-top: -5px;" autocomplete="off"
                    placeholder="{{'contact.yourPosition' | translate}}" formControlName="position" required
                    [ngClass]="{ 'is-invalid': f.position.touched && f.position.errors, 'is-valid': f.position.valid }">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <i class="mdi mdi-package-variant-closed text-primary h4 mr-2"></i>
                  <label for="email" class="font-size-15"> {{'contact.product' | translate}} </label>
                  <select id="email" type="email" class="form-control" style="margin-top: -5px;"
                    formControlName="product" required
                    [ngClass]="{ 'is-invalid': f.product.touched && f.product.errors, 'is-valid': f.product.valid }">
                    <option value="" disabled selected hidden>{{'contact.yourProduct' | translate}}</option>
                    <option value="SOFICloud">SOFICloud</option>
                    <option value="PISICloud">PISICloud</option>
                    <option value="Customized Project">{{'contact.optionCustomize' | translate}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <i-feather class="icon-xs icon mr-2 text-primary" name="message-circle"></i-feather>
                  <label for="comments" class="font-size-15"> {{'contact.message' | translate}} </label>
                  <textarea id="comments" rows="4" class="form-control" placeholder="{{'contact.yourMessage' | translate}}" formControlName="message"
                    required
                    [ngClass]="{ 'is-invalid': f.message.touched && f.message.errors, 'is-valid': f.message.valid }"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mb-3">
                <re-captcha (resolved)="resolvedRecaptcha($event)" siteKey="6LcO-CghAAAAAF1bTs8ZyPzjOIP_CCL2ga19emLW">
                </re-captcha>
              </div>
              <div class="col-sm-12">
                <button type="button" class="btn btn-primary" (click)="sendMessage()" [disabled]="!contactForm.valid">
                  {{'contact.buttonSend' | translate}} <i-feather class="icon-size-15 icon" name="send"></i-feather>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-xl-1"></div>

      <div class="col-xl-4 contact-pc">
        <h3>PT Inforsys Indonesia</h3>
        <hr>
        <div class="contact-detail">
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="mail"></i-feather>
            <span>{{ emailContact }}</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="link"></i-feather> <span>inforsys.co.id</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="phone-call"></i-feather> <span>+62 778 416
              0250</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="message-circle"></i-feather> <span>+62 811 7774
              744</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="clock"></i-feather> <span>8:00 AM - 5:00 PM</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="map-pin"></i-feather>
            <span>Orchard Park Blok C No.18</span> <br>
            <span class="address-text">Batam, Kepulauan Riau 29464, Indonesia</span>
          </p>
        </div>
      </div>
    </div>

    <ngb-alert *ngIf="isSuccess" type="success" (closed)="isSuccess = false">Pesan berhasil dikirim</ngb-alert>
  </div>
</section>