import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SoftwareImplementationComponent } from './software-implementation/software-implementation.component';
import { TrainingComponent } from './training/training.component';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CustomizeModuleComponent } from './customize-module/customize-module.component';
import { AboutComponent } from './about/about.component';
import { Covid19Component } from './covid19/covid19.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ClientsComponent } from './clients/clients.component';
import { ProductSoficloudComponent } from './product-soficloud/product-soficloud.component';
import { ProductPisicloudComponent } from './product-pisicloud/product-pisicloud.component';
import { ProductCustomizeComponent } from './product-customize/product-customize.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'clients',
    component: ClientsComponent
  },
  // {
  //   path: 'privacy',
  //   component: PrivacyComponent
  // },
  // {
  //   path: 'terms-conditions',
  //   component: TermsConditionsComponent
  // },
  // {
  //   path: 'annual-maintenance',
  //   component: AnnualMaintenanceComponent
  // },
  {
    path: 'customize-module',
    component: CustomizeModuleComponent
  },
  {
    path: 'product-soficloud',
    component: ProductSoficloudComponent
  },
  {
    path: 'product-pisicloud',
    component: ProductPisicloudComponent
  },

  {
    path: 'product-customize',
    component: ProductCustomizeComponent
  },
  {
    path: 'software-implementation',
    component: SoftwareImplementationComponent
  },
  {
    path: 'training',
    component: TrainingComponent
  },
  {
    path: 'pernyataan-resmi-inforsys-terkait-covid-19',
    component: Covid19Component
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
