<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo" routerLink="/home">
      <img src="assets/images/inforsys.png" alt="" class="logo-dark" height="32" />
    </a>
    <button class="btn-mobile" (click)="goToWA()">
      {{'header.contact' | translate}}
    </button>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">


        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.product' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" routerLink="/product-soficloud">
                <img src="assets/images/logo-soficloud.png" width="13%" class="mr-1"><strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17"><b>SOFICloud ERP</b></strong> <br>
                <span *ngIf="!isMobile" class="font-size-14"> {{'header.product1Sub' | translate}} </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" routerLink="/product-pisicloud">
                <img src="assets/images/logo-pisicloud.png" width="6%" class="mr-2 ml-1"> <strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17">PISICloud</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">Payroll, Attandance, Personnel System</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" routerLink="/product-customize">
                <img src="assets/images/logo-customize.png" width="8%" class="mr-2"> <strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17">Customize Project</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14"> {{'header.product2Sub' | translate}} </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.aboutInforsys' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" routerLink="/about">
                <i class="mdi mdi-book-open-page-variant h3 mr-3" style="color: #fd9644"></i> <strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17"> {{'header.aboutInforsys1' | translate}} </strong>
                <br>
                <span *ngIf="!isMobile" class="font-size-14"> {{'header.aboutInforsys1Sub' | translate}} </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" routerLink="/clients">
                <i class="mdi mdi-account-group h3 mr-3" style="color: #3e87db;"></i> <strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17"> {{'header.aboutInforsys2' | translate}} </strong>
                <br>
                <span *ngIf="!isMobile" class="font-size-14"> {{'header.aboutInforsys2Sub' | translate}}
                  &nbsp;&nbsp;</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a routerLink="/contact" class="nav-link"> {{'header.contact' | translate}} </a>
        </li>
        <li class="nav-item">
          <a routerLink="/blog" class="nav-link">Blog</a>
        </li>
        <li class="line"></li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false" style="border-bottom: none;">
            <span *ngIf="savedLang === 'id' || !savedLang">
              <img src="assets/images/indonesia-flags.png" width="13%" class="mr-2"> Indonesia
            </span>
            <span *ngIf="savedLang === 'en'">
              <img src="assets/images/us-flag.png" width="15%" class="mr-2"> English
            </span>
            <span *ngIf="savedLang === 'cn'">
              <img src="assets/images/china-flag.png" width="17%" class="mr-2"> 中国
            </span>
            <span *ngIf="savedLang === 'jp'">
              <img src="assets/images/japan-flag.png" width="20%" class="mr-2"> 日本
            </span>
            <span *ngIf="savedLang === 'kr'">
              <img src="assets/images/korea-flag.ico" width="20%" class="mr-2"> 日本
            </span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="flag" (click)="onChangeLang('id')"
              *ngIf="savedLang === 'en' || savedLang === 'cn' || savedLang === 'jp' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/indonesia-flags.png" width="13%" class="mr-2"> Indonesia
                </strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('en')"
              *ngIf="savedLang === 'id' || savedLang === 'cn' || savedLang === 'jp' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/us-flag.png" width="13%" class="mr-2"> English</strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('cn')"
              *ngIf="savedLang === 'id' || savedLang === 'en' || savedLang === 'jp' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/china-flag.png" width="13%" class="mr-2"> 中国 </strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('jp')"
              *ngIf="savedLang === 'id' || savedLang === 'en' || savedLang === 'cn' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/japan-flag.png" width="13%" class="mr-2"> 日本 </strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('kr')"
              *ngIf="savedLang === 'id' || savedLang === 'en' || savedLang === 'cn' || savedLang === 'jp' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/korea-flag.ico" width="13%" class="mr-2"> 한국 </strong>
                <br>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav navbar-center">
        <li class="nav-item">
          <button class="btn btn-primary btn-sm btn-pc" (click)="goToWA()">
            {{'header.contact' | translate}}
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Navbar End -->