<section class="section">
  <div class="container" style="margin-top: -5em; margin-bottom: -5em;">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="mb-3"> <span class="text-primary"> {{'customerSlide.header-1' | translate}} </span> {{'customerSlide.header-2' | translate}} <span class="text-primary"> {{'customerSlide.header-3' | translate}} </span> </h3>
          <hr style="border: 2px solid #CF0E0E; width: 35%">
          <p class="font-size-16"> {{'customerSlide.subHeader' | translate}}
          </p>
          <a href="/clients" class="text-primary font-weight-semibold"> {{'customerSlide.otherCustomers' | translate}} <span
              class="ml-2 right-icon">&#8594;</span></a>
        </div>
      </div>
    </div>
    <div class="row align-items-center h-100">
      <div class="container rounded">
        <div class="slider">
          <div class="logos">
            <img class="fab" src="assets/images/customer/36.jpg" style="width :80px">
            <img class="fab" src="assets/images/customer/6.png">
            <img class="fab" src="assets/images/customer/37.jpg">
            <img class="fab" src="assets/images/customer/25.png">
            <img class="fab" src="assets/images/customer/38.jpg" style="width :55px">
            <img class="fab" src="assets/images/customer/22.jpg">
            <img class="fab" src="assets/images/customer/4.png">
            <img class="fab" src="assets/images/customer/3.png" style="width :90px">
            <img class="fab" src="assets/images/customer/39.png">
            <img class="fab" src="assets/images/customer/91.png" style="width :70px">
            <img class="fab" src="assets/images/customer/10.png">
            <img class="fab" src="assets/images/customer/2.jpg">
            <img class="fab" src="assets/images/customer/41.png">
            <img class="fab" src="assets/images/customer/42.png">
            <img class="fab" src="assets/images/customer/43.png">
            <img class="fab" src="assets/images/customer/44.png">
            <img class="fab" src="assets/images/customer/5.png">
            <img class="fab" src="assets/images/customer/45.jpg">
            <img class="fab" src="assets/images/customer/11.png">
            <img class="fab" src="assets/images/customer/1.png">
            <img class="fab" src="assets/images/customer/46.png" style="width :80px">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>